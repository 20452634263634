import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  colors: {
    medium_aquamarine: "#6BD2A1",
    aquamarine: "#7BF9BD",
    antique_white: "#FFF0DB",
    timberwolf: "#D3CDC7",
    bitter_lemon: "#CFE800",
    green_sheen: "#72B49F",
    jet: "#2A2A2A",
  },
  components: {
    Button: {
      baseStyle: {},
      variants: {
        text: {
          fontWeight: "bold",
          fontSize: "18px",
          lineHeight: "28px",
          letterSpacing: "1.08px",
          textColor: "aquamarine",
          textDecoration: "underline",
          border: "none",
          padding: "0px",
          margin: "0px",
          _focus: {
            boxShadow: "none",
          },
        },
      },
      defaultProps: {
        variant: "text",
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: "Satoshi",
      },
      variants: {
        h1: {
          fontWeight: "normal",
          fontSize: "43px",
          lineHeight: "54px",
          letterSpacing: "0px",
          textColor: "bitter_lemon",
        },
        h2: {
          fontWeight: "medium",
          fontSize: "34px",
          lineHeight: "46px",
          letterSpacing: "0px",
          textColor: "bitter_lemon",
        },
        h3: {
          fontWeight: "bold",
          fontSize: "24px",
          lineHeight: "37px",
          letterSpacing: "0px",
          textColor: "antique_white",
        },
        label: {
          textAlign: "center",
          fontSize: "15px",
          letterSpacing: ".9px",
          lineHeight: "21px",
          textColor: "bitter_lemon",
          textTransform: "uppercase",
          pt: "13px",
        },
        service_label: {
          pb: "20px",
          textAlign: "center",
          fontSize: "34px",
          textColor: "jet",
          fontWeight: "medium",
        },
      },
    },
    Text: {
      variants: {
        body: {
          fontSize: "18px",
          lineHeight: "28px",
          letterSpacing: "0px",
          textColor: "antique_white",
        },
      },
    },
  },
  styles: {
    global: {
      "html, body": {
        scrollBehavior: "smooth",
        fontFamily: "Satoshi",
      },
    },
  },
});
