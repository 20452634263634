import { ChakraProvider, Flex } from "@chakra-ui/react";
import { Head } from "next/head";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { theme } from "../styles/chakra-theme";
import "../styles/fonts/satoshi.css";

const App = ({ Component, pageProps }) => {
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey="6Ld5baMaAAAAAMk-_oW9K6UXnEXZV67k5c9f8u_Y">
        <ChakraProvider theme={theme} resetCSS>
          <Component {...pageProps} />
        </ChakraProvider>
      </GoogleReCaptchaProvider>
    </>
  );
};

export default App;
